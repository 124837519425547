.logo {
  width: 150%;
  height: 100%;
}

.nav-theme {
  background: transparent;
  font-size: 20px;
}

.animate-navbar {
  box-shadow: 1px 1px 1px #222;

  animation: moveDown 0.5s ease-in-out;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

.navMenu {
}
@media screen and (max-width: 700px) {
  .navMenu {
    display: none !important;
  }
}
