.img-resize {
  width: 8em;
}

.style {
  font-size: 20px;
}

.jumbo-style {
  background-image: linear-gradient(
    -225deg,
    #2cd8d5 0%,
    #6b8dd6 48%,
    #8e37d7 100%
  );
}

.body-title-style {
  font-size: 25px;
  font-weight: 800;
}
.experienceCard {
  padding: 1rem !important;
  margin: 2rem 0 !important;
}

@media screen and (max-width: 600px) {
  .experienceCard {
    width: 100% !important;

    padding: 0 !important;
  }
}
